<template>
    <div id="app" class="app">
        <Header class="app__header"/>
        <transition name="app__content" mode="out-in">
            <router-view
                :key="$route.path"
                class="app__view"
            />
        </transition>
        <Footer class="app__footer" />

	    <div class="app__background" />
    </div>
</template>


<script>
    import { mapGetters } from 'vuex';
    import Header from './components/header/header';
    import Footer from './components/footer/footer';

    export default {
        components: {
            Header,
            Footer,
        },
        computed: {
            ...mapGetters([
                'isAuthenticated',
            ]),
        },
        async created() {
            await this.$store.dispatch('getAvailableTariffs');
    
            if (this.isAuthenticated){
                await Promise.all([
                    this.$store.dispatch('getProfileInfo'),
                    this.$store.dispatch('getProfileAnalyzes'),
                ]);
            }
        },
    }
</script>

<style lang="less">
    .app{
        position: relative;
        
        &__header{
            position: absolute;
            left: 0;
            top: 0;
            z-index: 3;
            width: 100%;
        }

        &__view{
            width: 100%;
            position: relative;
            z-index: 2;
            padding-top: 20px;
            min-height: 100vh;
            padding-bottom: 50px;

            @media @mobile{
                padding-top: 0;
            }
        }
        
        &__footer {
	        position: relative;
	        z-index: 1;
        }

	    &__background {
		    .cover;
		    .img('bg.jpg');
		    background-color: @color-purple;
		    background-position: top;
		    background-size: cover;
		    position: fixed;
		    left: 0;
		    top: 0;
		    width: 100%;
		    height: 100%;
		    z-index: 0;

		    @media @mobile {
			    .img('bg-mobile.jpg');
		    }
	    }
    }
</style>
